import React, { useState, useRef, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Slider from "react-slick";
import Breadcrumb from "../components/elements/breadcrumb";
import pageUrl from "../utility/page-url";
import withPreview from "../utility/with-preview";
import { FormattedMessage } from "react-intl";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import CloseIcon from "../images/icons/cancel_circle.svg";

const filters = [
  {
    name: `Tutti`,
    value: "all",
    icon: `list`,
  },
  {
    name: `Messaggio CEO`,
    value: "messaggio_ceo",
    icon: `envelope`,
  },
  {
    name: `Responsabilità Ambientale`,
    value: "impatto_ambientale",
    icon: `north-earth`,
  },
  {
    name: `Responsabilità Sociale`,
    value: "impatto_sociale",
    icon: `family-1`,
  },
  {
    name: `Responsabilità Economica`,
    value: "impatto_economico",
    icon: `diagram`,
  },
  {
    name: `Governance`,
    value: "governance",
    icon: `person-rotate`,
  },
];

const settings = {
  dots: false,
  infinite: false,
  arrows: false,
  swipe: true,
  slidesToShow: 6,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        centerMode: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        centerMode: true,
        centerPadding: "24px",
      },
    },
  ],
};

const ReportBilancioPage = ({
  pageContext: { langCode },
  data: { meta, page },
  location,
}) => {
  const { htmlTitle, mainTitle, mainSubtitle, card } = page;

  const canonical =
    (htmlTitle.content &&
      htmlTitle.content.canonicalUrl &&
      htmlTitle.content.canonicalUrl.value) ||
    `${meta.siteUrl}/${pageUrl(page)}`;

  const [currentFilter, setCurrentFilter] = useState("all");
  const [currentCard, setCurrentCard] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [imgVisible, setImgVisible] = useState(1);
  const modalRef = useRef(null);

  useEffect(() => {
    const pressKey = (e) => {
      if (e.key === "Escape") {
        modalRef.current.scrollTo(0, 0);
        setModalVisible(false);
        setImgVisible(1);
      }
    };
    document.addEventListener("keydown", pressKey);
    return () => document.removeEventListener("keydown", pressKey);
  }, []);

  useEffect(() => {
    let cardCollection = document.getElementsByClassName(
      "tile-list__grid-item"
    );
    for (let cardItem of cardCollection) {
      cardItem.classList.add("tile-list__grid-item--filter");
    }
    setTimeout(() => {
      for (let cardItem of cardCollection) {
        cardItem.classList.remove("tile-list__grid-item--filter");
      }
    }, 1000);
  }, [currentFilter]);

  return (
    <>
      <Layout langCode={langCode} location={location}>
        <Seo
          title={htmlTitle.value || page.pageTitle.value}
          langCode={langCode}
          // ogImage={htmlTitle.content.ogImage}
          canonical={canonical}
          description={
            htmlTitle.content.metaDescription &&
            htmlTitle.content.metaDescription.value
          }
        />
        <div className="newsroom">
          <div className="newsroom__intro d-flex flex-column justify-content-center">
            <div className="container">
              <Breadcrumb page={page} className="breadcrumb--dark" />
              {mainTitle?.value && (
                <div className="row">
                  <div className="col-12 pt-6">
                    <h1
                      className="hero-icons__title mb-2"
                      dangerouslySetInnerHTML={{ __html: mainTitle.value }}
                    ></h1>
                  </div>
                </div>
              )}
              {mainSubtitle && mainSubtitle?.value ? (
                <div className="row">
                  <div className="col-lg-5">
                    <div
                      className="newsroom__subtitle pb-4"
                      dangerouslySetInnerHTML={{ __html: mainSubtitle.value }}
                    ></div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="pb-5 mb-5">
            <div className="filters pb-5">
              <span id="filters" className="scroll-anchor"></span>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h4 style={{ color: "#0af" }}>
                      <FormattedMessage id="filter-by" />:
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-xl-8 d-flex pt-4">
                    <Slider {...settings} className="filters__slider w-100">
                      {filters.map((filter) => (
                        <button
                          key={filter.value}
                          className={`filters__filter filters__filter--colored d-inline-flex flex-column align-items-center w-100${
                            currentFilter === filter.value
                              ? " filters__filter--active"
                              : ""
                          }`}
                          onClick={() => setCurrentFilter(filter.value)}
                        >
                          <i
                            className={`nj-icon nj-icon--blue-corporate nj-icon-${
                              filter.icon
                            } ${
                              currentFilter === filter.value
                                ? "nj-icon--circle"
                                : "nj-icon--border"
                            }`}
                          ></i>
                          <div
                            className={`filters__name text-center mt-1${
                              currentFilter === filter.value
                                ? " text-blue-corporate"
                                : ""
                            }`}
                          >
                            {filter.name}
                          </div>
                        </button>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            <div className="tile-list text-white">
              <div className="container-fluid">
                <div className="tile-list__grid">
                  {card
                    .filter((item) =>
                      currentFilter === "all"
                        ? true
                        : item.content.cardCategory.value[0] === currentFilter
                    )
                    .map((tile, i) => (
                      <div className="tile-list__grid-item" key={i}>
                        <div className="tile-list__tile p-5 d-flex flex-column">
                          {tile.content?.cardImage &&
                            tile.content?.cardImage?.node && (
                              <Img
                                objectFit="cover"
                                image={getImage(tile.content.cardImage.node)}
                                alt={tile.content.cardImage.description || ""}
                                className="tile-list__img"
                              />
                            )}
                          <div className="tile-list__opacity"></div>
                          {tile.content?.cardTitle?.value && (
                            <h3
                              className="tile-list__title position-relative text-white"
                              dangerouslySetInnerHTML={{
                                __html: tile.content.cardTitle.value,
                              }}
                            ></h3>
                          )}
                          {tile?.content?.cardLabel?.value && (
                            <span className="tile-list__label position-relative text-white">
                              {tile.content.cardLabel.value}
                            </span>
                          )}
                          <button
                            className="tile-list__btn nj-btn position-relative nj-btn--primary mt-5"
                            onClick={() => {
                              setCurrentCard(
                                card.findIndex(
                                  (element) =>
                                    element.content?.cardTitle?.value ===
                                    tile.content?.cardTitle?.value
                                )
                              );
                              setModalVisible(true);
                            }}
                          >
                            Scopri
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <div
        id="modal"
        ref={modalRef}
        className={`report-modal ${modalVisible ? "open" : ""}`}
      >
        <div className="report-modal__container">
          <div className="report-modal__slider">
            <div
              className={`report-modal__translate${
                imgVisible === 2 ? " report-modal__translate--switch" : ""
              }`}
            >
              <Img
                image={getImage(
                  card[currentCard].content?.detailCardFirst?.node
                )}
                alt={
                  card[currentCard].content?.detailCardFirst?.description || ""
                }
                className="report-modal__img"
              ></Img>
              <Img
                image={getImage(
                  card[currentCard].content?.detailCardSecond?.node
                )}
                alt={
                  card[currentCard].content?.detailCardSecond?.description || ""
                }
                className="report-modal__img"
              ></Img>
            </div>
          </div>
          <div className="report-modal__bottom">
            <button
              className="tile-list__btn nj-btn position-relative nj-btn--primary mt-3"
              onClick={() => {
                setImgVisible(imgVisible === 1 ? 2 : 1);
                modalRef.current.scrollTo(0, 0);
              }}
            >
              {card[currentCard].content.cardCategory.value[0] ===
              "messaggio_ceo"
                ? imgVisible === 1
                  ? "Continua"
                  : "Indietro"
                : imgVisible === 1
                ? "Leggi di più"
                : "Leggi di meno"}
            </button>
          </div>
          <button
            id="closeModal"
            className="report-modal__close"
            onClick={() => {
              modalRef.current.scrollTo(0, 0);
              setModalVisible(false);
              setImgVisible(1);
            }}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default withPreview(ReportBilancioPage, {
  subField: "page",
  fixed: true,
});
export const query = graphql`
  query ReportBilancioQuery($liferayId: Int!, $langCode: String!) {
    meta: gatsbySourceLiferayOptions {
      siteUrl
    }
    page: liferayPaginareportbilancio(
      liferayId: { eq: $liferayId }
      langCode: { eq: $langCode }
    ) {
      siteId
      articleId
      langCode
      pageTitle {
        value
        content {
          friendlyUrl {
            value
          }
        }
      }
      htmlTitle {
        value
        content {
          metaDescription {
            value
          }
          canonicalUrl {
            value
          }
        }
      }
      mainTitle {
        value
      }
      mainSubtitle {
        value
      }
      card {
        content {
          cardCategory {
            value
          }
          cardImage {
            node {
              gatsbyImageData
            }
          }
          cardLabel {
            value
          }
          cardTitle {
            value
          }
          detailCardFirst {
            node {
              gatsbyImageData
            }
          }
          detailCardSecond {
            node {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
